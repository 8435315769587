import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Masonry from 'react-masonry-css'

import LayoutTransparent from '../components/LayoutTransparent/LayoutTransparent'

const ns = `journal-page`

const JournalPage = ({ data }) => {
  const {
    allMarkdownRemark: { nodes: content },
  } = data

  const {
    markdownRemark: { frontmatter: hero },
  } = data

  useEffect(() => {
    document.body.style.backgroundColor = '#1E0324'
    return function cleanup() {
      document.body.style.backgroundColor = '#ffffff'
    }
  }, [])
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const breakpointColumnsObj = {
    default: 2,
    1024: 1,
  }

  const textPost = textData => (
    <div className={`${ns}__quote-post ${ns}__post`}>
      <div className="quote-content">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {textData.frontmatter.text_post.text}
        </ReactMarkdown>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} className="attribution">
          {textData.frontmatter.text_post.attribution}
        </ReactMarkdown>
      </div>
    </div>
  )

  const imagePost = imageData => (
    <div className={`${ns}__single-image-post ${ns}__post`}>
      {imageData.frontmatter.image_post.images.map(image => (
        <img src={image.image.publicURL} alt="" />
      ))}
      <ReactMarkdown rehypePlugins={[rehypeRaw]} className="attribution">
        {imageData.frontmatter.image_post.attribution}
      </ReactMarkdown>
    </div>
  )

  const articlePost = articleData => (
    <div className={`${ns}__article-post ${ns}__post`}>
      <Link to={articleData.fields.slug}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} className="title">
          {articleData.frontmatter.article_post.title}
        </ReactMarkdown>
      </Link>
      <Link to={articleData.fields.slug}>
        <img
          src={
            articleData.frontmatter.article_post.featured_image_section
              ?.featured_image.publicURL
          }
          alt={articleData.frontmatter.label}
        />
      </Link>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {`${articleData.frontmatter.article_post.body.substring(0, 200)}...`}
      </ReactMarkdown>
    </div>
  )

  const videoPost = videoData => (
    <div className={`${ns}__video-post ${ns}__post`}>
      <video muted controls>
        <source
          src={videoData.frontmatter.video_post.video_url}
          type="video/mp4"
        />
      </video>
      <p className="attribution">
        {videoData.frontmatter.video_post.attribution}
      </p>
    </div>
  )

  const renderSection = sectionData => {
    if (sectionData.frontmatter.text_post.post_visible === true) {
      return textPost(sectionData)
    }
    if (sectionData.frontmatter.image_post.post_visible === true) {
      return imagePost(sectionData)
    }
    if (sectionData.frontmatter.article_post.post_visible === true) {
      return articlePost(sectionData)
    }
    if (sectionData.frontmatter.video_post.post_visible === true) {
      return videoPost(sectionData)
    }
  }

  return (
    <LayoutTransparent>
      <div className={rootClassnames}>
        <div
          className={`${ns}__header row no-gutters justify-content-center align-items-center`}
        >
          <div className="col-md-6">
            <img
              className="img-fluid logo"
              src={hero.hero_section.left_image.publicURL}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={hero.hero_section.right_image.publicURL}
              alt=""
            />
          </div>
        </div>
        <div className={`${ns}__content `}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {content.map((item, index) => renderSection(item, index))}
          </Masonry>
        </div>
      </div>
    </LayoutTransparent>
  )
}

export default JournalPage

export const journalPageQuery = graphql`
  query JournalPage($id: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "journal-post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          label
          date
          text_post {
            post_visible
            text
            attribution
          }
          image_post {
            post_visible
            images {
              image {
                publicURL
              }
            }
            attribution
          }
          article_post {
            post_visible
            title
            featured_image_section {
              featured_image_visible
              featured_image {
                publicURL
              }
            }
            body
            two_columns {
              column_visible
              left_column
              right_columns {
                image {
                  publicURL
                }
                attribution
              }
            }
          }
          video_post {
            post_visible
            video_url
            attribution
          }
        }
        fields {
          slug
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero_section {
          left_image {
            publicURL
          }
          right_image {
            publicURL
          }
        }
      }
    }
  }
`
